<template>
  <div>
    <v-container >
      <v-row>
        <app-card customClasses="mb-5">
          <h3>DASHBOARD</h3>
        </app-card>

      </v-row>
    </v-container>
  </div>
</template>

<script>
import UserWarehouseServices from '@/store/services/warehouse/UserWarehouseServices';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      UserWarehouseServices
    };
  },
  created() {
    this.getWarehouse();
  },
  methods: {
    async getWarehouse() {
      if (this.user.warehouse === null) {
        try {
          const params = {
            search_user: this.user.id,
            include: 'warehouse',
          };
          const response = await UserWarehouseServices.index(this.user.archon.id, params);
          const warehouses = response.data.data.filter(warehouse => warehouse.user_id === this.user.id);
          if (warehouses.length > 0) {
            const user = Object.assign({}, this.user);
            user['warehouse'] = warehouses[0].warehouse;
            this.$store.dispatch('setSession', {
              user,
              access_token: this.token,
              token_type: 'Bearer',
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      token: 'getToken',
    }),
  },
}
</script>

<style>

</style>